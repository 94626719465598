<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img class="dark align-center" :src="FiveToolProspect"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <p>The game of baseball is built on players having 5 tools:</p>
          <ul>
            <li>Hit for Contact</li>
            <li>Hit for Power</li>
            <li>Base Running</li>
            <li>Fielding</li>
            <li>Throwing</li>
          </ul>
          <p class="mt-2">
            We created the "5-Tool Prospect" program designed specifically to
            develop these 5 tools!
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Tricks of the Trade:</h4>
          <p>
            The game of baseball doesn't just stop at having the 5 tools. Ball
            players must also know the tricks of the trade, such as:
          </p>
          <ul>
            <li>Arm Care</li>
            <li>Hitting Approach</li>
            <li>Being a Good Teammate</li>
          </ul>
          <p class="mt-2">
            Athletes in the program will learn all of these concepts and more
            from our experienced coaches!
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Session Dates:</h4>
          <p>
            Winter: December - February
            <br />
            Spring: March - May
            <br />
            Summer: June - August
            <br />
            Fall: September - November
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <h4>Pricing:</h4>
          <p>
            - $109.00 per month - 1x/week
            <br />
            - $139.00 per month - 2x/week
          </p>
          <ContactButton title="Sign Up" />
        </v-col>
      </v-row>
      <v-img class="dark align-center mt-4" :src="PDTrain"></v-img>
    </v-container>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import PDTrain from '@/assets/images/PDTrain.svg'
import FiveToolProspect from '@/assets/images/5ToolProspect.svg'

export default {
  components: {
    ContactButton,
  },
  data() {
    return {
      reveal: false,
      drawer: null,
      PDTrain: PDTrain,
      FiveToolProspect: FiveToolProspect,
    }
  },
}
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
